<template>
	<span
		:data-href="msgLink"
		data-qa="activityBodyLink-1"
		class="link link--blue"
		@click.prevent="redirect"
		>{{ msgText }}</span
	>
</template>

<script lang="ts" setup>

import { useUserStore } from '@/stores/user'
import { useMessagesStore } from '@/stores/messages'
import { useMemberStructureStore } from '@/stores/memberStructure'
import { storeToRefs } from 'pinia'
import {Inkasso} from "@/models/Messages/Inkasso";

const { user } = storeToRefs(useUserStore())
const { selectedNachricht } = storeToRefs(useMessagesStore())
const { allMembers } = storeToRefs(useMemberStructureStore())

const props = defineProps<{
	msgLink: string,
	msgText: string,
}>()

function checkForContextSwitch (selectedNachrichtMemberId?: string): boolean {
    if (!selectedNachrichtMemberId) { return false }
    const actForId = sessionStorage.getItem('actForMemberId')
    const userMemberId = user.value?.cr_membernumber

    return selectedNachrichtMemberId !== actForId && (!!actForId || selectedNachrichtMemberId !== userMemberId)
}

function isAllowed(): boolean {
    return user.value.cr_membernumber !== undefined && allMembers.value.includes(user.value.cr_membernumber)
}

function setActFor(selectedNachrichtId?: string): string {
    if (checkForContextSwitch(selectedNachrichtId)) {
        window.dispatchEvent(new CustomEvent('switchContext', { detail: { 'actForMemberId': selectedNachrichtId, redirectOnChange: false } }))
    }
    return selectedNachrichtId || ''
}

function redirect(): void {
    if (isAllowed() && !isMessageIkarosInkasso()) {
        setActFor(selectedNachricht.value?.memberId)
    }
    window.location.href = props.msgLink
}

function isMessageIkarosInkasso(): boolean {
    if (selectedNachricht.value?.nachrichtType !== 'INKASSO') {
        return false
    } else if ((selectedNachricht.value as Inkasso).attributes.inkassoType === 'Ikaros') {
        return true
    }
    return false
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/vars/colors.scss';
.link {
	&--blue {
		text-decoration: none;
		background-color: transparent;
		color: $clr-brand-blue;
		transition: color 0.15s ease-in-out;
		cursor: pointer;
		&:hover {
			color: $clr-brand-darkest-blue;
			text-decoration: none;
		}
	}
}
</style>
